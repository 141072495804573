<template>
  <section v-if="!slice.primary.hide_section" class="home__multi-callout large-pad">
    <div class="row">
      <div v-for="callout in slice.items" class="column small-12 large-6">
        <div class="image-callout-card">
          <PrismicLazyImage
            :image="callout.background_image"
            :link="callout.link"
            width="960"
            height="560"
          />
          <div class="image-callout-card__content">
            <h3 v-if="callout.title">{{ callout.title | getTitle }}</h3>
            <ArrowLink v-if="callout.link_text" :text="callout.link_text" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import ArrowLink from '@/components/global/ArrowLink';

  export default {
    name: 'HomepageImageMultiCallout',

    components: {
      PrismicLazyImage,
      ArrowLink
    },

    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    }
  }
</script>
